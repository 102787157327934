import React, { useState } from 'react';
import { Drawer, Button, Form, Input, Menu } from 'antd';
import './register.css'
import axios from 'axios';
import close from '../Login/login_img/close.png'
import { withRouter } from "react-router";



const BankerRegister = (props) => {
  
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [error, setError]= useState(false);
  const [success, setSuccess] = useState(false);
  const [loader, setloader]= useState(false)

  const showDrawer = () => {
    setVisible(true);

  };

  const onClose = () => {
    setVisible(false);
    setError(false)
    setSuccess(false)
    form.resetFields();
  };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};




  const onFinish = (e) => {
    setloader(true)
    setError(false)
    setSuccess(false)
    
    axios.post('https://efb.ae/bankerRegister/add', e )
     .then(res=> {
      form.resetFields()
      console.log(res.data)
      setSuccess(true)
      setError(false)
      setloader(false)
      props.history.push("/")

      })

     .catch(err=>{
       console.log(err);
       setError(true);
       setSuccess(false)
       form.resetFields();
       setloader(false)

     })

  };

  return (
    <>
      <Menu.Item key="1" onClick={showDrawer}>Bankers Register</Menu.Item>
      
      <Drawer
      title="Banker Sign up"
      placement="right"
        // onClose={onClose}
        visible={visible}
      >
        {loader ?
         <p class="alert-text">Processing....</p>
        : null }

        {success ? 
            <p class="alert-pass-text">Registered Successfully</p>
            : null}  
                    
        {error ?  
            <p class="alert-text">Email or Phone Number exists</p>
            : null} 
        
       <section class="form-section"style={{height: "100%"}}>
         <div><img class="close-img" src={close} alt="close" onClick={onClose}></img></div>
              <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{
                residence: ['zhejiang', 'hangzhou', 'xihu'],
                prefix: '86',
              }}
              scrollToFirstError
            >

               <Form.Item name={['Name']} label="Name" rules={[{ required: true }]}>
                <Input />
              </Form.Item>

              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[{ required: true, message: 'Please input your phone number!' }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  Register
                </Button>
              </Form.Item>
            </Form>
                                               
      </section>
      </Drawer>
    </>
  );
};

export default withRouter(BankerRegister);
import { Menu, Dropdown} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import '../components/dropdown.css'
import { Link } from 'react-router-dom';




const Serve =() =>{


const menu = (
    <Menu>
      <Link to={{
        pathname: '/services',
        state: {
          service: "ploan" }
      }}>
      <Menu.Item key="1">Personal Loan</Menu.Item></Link>

      <Link to={{
        pathname: '/services',
        state: {
          service: "mortgage" }
      }}>
      <Menu.Item key="2">Mortgage</Menu.Item></Link>

      <Link to={{
        pathname: '/services',
        state: {
          service: "bf" }
      }}>
      <Menu.Item key="3">Business loan</Menu.Item></Link>

      <Link to={{
        pathname: '/services',
        state: {
          service: "ba" }
      }}>
      <Menu.Item key="4">Business Account</Menu.Item></Link>

      <Link to={{
        pathname: '/services',
        state: {
          service: "credit" }
      }}>
      <Menu.Item key="5">Credit Cards</Menu.Item></Link>
      
    </Menu>
  );

             
    return(
        <Dropdown overlay={menu}>
    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
      Services <DownOutlined />
    </a>
  </Dropdown>   
    
    )
}


export default Serve;
import React, { useState } from 'react';
import {
  Form,
  Input,
  Button,

  Select,

  DatePicker,
  Drawer,
  
} from 'antd';
import '../Login/register.css'
import './s.css';
import  moment from 'moment'
import axios from 'axios'



const BookAppointmentServices= (history) => {
  
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  
  const showDrawer = () => {
    setVisible(true);

  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
       
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    }
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  const onFinish = (e) => {
    form.resetFields();
        console.log(e);
     e.date = moment(e.date).format(
       "dddd, MMMM Do YYYY" 
     ) 
      axios.post('https://efb.ae/appointments/add', e )
        .then(res=> {console.log(res.data)})
        .catch(err=>{
          console.log(err)
        })
  };

  return (
    <>
      <Button id="book" type="primary" onClick={showDrawer}>Book Appointment</Button>
      
      
      <Drawer
        title="Appointment"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Form {...layout} name="nest-messages" onFinish={onFinish} validateMessages={validateMessages}>
        <Form.Item
              name={['user', 'name']}
              label="Name"
              rules={[
                {
                  required: true,
                },
              ]}
              className="ant-form-item-side-appoint"
            >
              <Input />
      </Form.Item>

        <Form.Item name={['user', 'email']} label="Email" rules={[{ type: 'email' }]}
        className="ant-form-item-side-appoint">
        <Input />
      </Form.Item>

      <Form.Item
        name={['user', 'phone']}
        label="Phone Number"
        rules={[{ required: true, message: 'Please input your phone number!' }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>

        <Form.Item label="Services"
        className="ant-form-item-side-appoint">
          <Select>
            <Select.Option value="Personal Loans">Personal Loans</Select.Option>
            <Select.Option value="Credit Cards">Mortgage</Select.Option>
            <Select.Option value="Mortgage">Business Loan</Select.Option>
            <Select.Option value="Auto Loan">Business Account</Select.Option>
            <Select.Option value="Bussiness Loans">Credit Cards</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Request A Call:" 
        className="ant-form-item-side-appoint">
          <DatePicker />
        </Form.Item>


        <Button className="bookAppointmentSide"
        >Submit Request</Button>

        </Form>
      </Drawer>
    </>
  );
};

export default BookAppointmentServices;
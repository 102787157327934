import React, { useRef} from 'react'
import Footer from '../components/footer'
import '../App.css'
import firstImage from '../img/firstImage.jpg'
import Header from '../components/head'
import '../components/slider.scss'
import Social from '../components/social'
import '../components/home.css'
import Experience from '../img/experience.png'
import best_offers from '../img/Best-Offers.png'
import clients from '../img/clients.png'
import Features from '../img/features.png'
import Slider from '../components/slider'
import about_home from '../img/about_coins.jpg';
import Booking from '../components/appoint'
import 'react-calendar/dist/Calendar.css';
import '../components/cal.css'
import Cal from './cal'
import { Link } from 'react-router-dom'

 const Dashboard = () =>{

    const bookingSection = useRef(null);
        const executeScroll = () => bookingSection.current.scrollIntoView() 
    
    return(

            
        <div class="background-display">
             <Social/>
            <Header/>
            {/* <section>
                
            </section> */}
           
            <section  id="section">
                <div class="firstImage" style={{ backgroundImage: `url(${firstImage})`}} >
                <h class="fisrt-image-text"> Get the best Deals on

                    <span><br/>PERSONAL LOANS, MORTGAGE</span>
                    <span style={{color:"rgb(7, 101, 243)"}}> & BUSSINESS FINANCE</span>
                    <span class="sentence"><br/> Get Your request processed on time without any hassles. </span>
                </h>
                <div class="first-image-appointment">
                    <button class="first-appointment-button" onClick={executeScroll}> 
                        Book Appointment
                    </button>

                </div>
                </div>     
               
            </section>
            <section id="section choose-section">
                <div class="choose">
                    <div class="experience p-width">
                        <div class="experience-img-header">
                        <img class="experience-img" src={Experience} alt="Experience"></img> 
                        <h class="experience-header">Extensive Years Of <b>Experience </b></h>
                        </div>
                        
                        <p class="patgforchoose">When it comes to finance
                             trust & experience becomes
                              vital. We work connect with 
                              right source to get your work done.</p>

                    </div>

                    <div class="best-offers p-width ">
                        <div class="experience-img-header">
                            <img class="experience-img" src={best_offers} alt="Experience"></img>
                            <h class="experience-header">Best Offers</h>
                        </div>
                        
                        <p class="patgforchoose">We provide best offers to every 
                            individual seeking financial help
                             from various banks in U.A.E.</p>

                    </div>

                    <div class="trust p-width">
                        <div class="experience-img-header">
                            <img class="experience-img" src={clients} alt="Clients"></img>
                            <h class="experience-header">Trusted <b> by 5000+ Clients</b></h>
                        </div>
                        
                        <p class="patgforchoose">We have serviced over 5000 
                            customers successfully, as
                             we understand finance & complete
                              financial services.</p>

                    </div>

                    <div class="feature p-width">
                        <div class="experience-img-header">
                            <img class="experience-img" src={Features} alt="Features"></img>
                            <h class="experience-header">Fast Feature Delivery</h>
                        </div>
                        
                        <p class="patgforchoose">We take a keen interest in 
                            fulfilling the requirements 
                            of people seeking financial 
                            assistance in the U.A.E.</p>

                    </div>
                </div>


            </section>
            <section  id="section" class="slider-section">
                <div class ="section-slider-shadow">
                <Slider/>
                </div>
            </section> 

            {/* <section style={{height:"80%" }}>
                <h1 class="working-process"><u class="uClass">Our</u> Working Process</h1>
            <Flow/>

            </section> */}

            <section id="section" class="about-home">
                <div class="about-home-one">
                    <div class="about-home-two">
                        <h class="about-header"> About Excellence Financing Broker</h>
                        <p class="about-para"> 
                            About Excellence Financing Broker
                            EXCELLENCE FINANCING BROKER works 
                            as a financial advisor to support
                            customers with the best financial
                            products suited to their needs.
                        </p>
                        <ul class="ul-about-home">
                            <li class="li-about-home">Our organization works on its core values of trust & loyalty</li>
                            <li class="li-about-home">We are trusted professionals with over 25 years of experience</li>
                            <li class="li-about-home">We use our knowledge, experience & intellect to find the best solutions</li>
                            <li class="li-about-home" >A platform to help small businesses & individuals with finance</li>
                        </ul>
                    </div>
                    <div class="about-home-img" >
                        <img class="about-img"src={about_home} alt="About EFB"></img>

                    </div>
                </div>
            </section>
            <section class="app-header-image" ref={bookingSection}>
               <div class="app-header-dv"> 
                <h class="make-appointment"   >Make Appointment</h>
                </div>
            </section>
            <section class="section-form">
                <Booking/>
                <div class="myCalender" style={{width: '50%'}}>
                    <Cal/>
                
                </div>

                {/* <button class="myCalButton">Reset</button>  */}
            </section>


            <Footer/>  

            <div class="credit-footer">
                <div class="credit-text">
                   <p class="credit-p-tag">@ All right resevred by EFB</p>
                   <Link to="/privacy-policy-and-disclaimer" class="credit-p-tag">Privacy Policy</Link>
                   <Link to="/privacy-policy-and-disclaimer" class="credit-p-tag">Disclaimer</Link>
                </div>
            </div>
        </div>
    )
}

export default Dashboard;
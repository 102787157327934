import React, { useRef, useEffect}  from 'react'

import Header from '../head'
import Footer from '../footer'
import service from './service_images/service.png'
import '../services/s.css'
import Social from '../social'
import BookAppointmentServices from './bookAppointmentServices'
import { withRouter } from "react-router";

const Service = (props) =>{

   
    useEffect(() => {
        switch(props.location.state.service){

            case "ploan":
                executeploan();
                break;

                case "mortgage":
                    executemortgage();
                    break;
                    
                case "ba":
                    executeba();
                break;

                case "bf":
                    executebf();
                break;

                case "credit":
                    executecredit();
                break;
            
            default:
                executeploan();
        }
    })
   
        const ploan = useRef(null);
        const executeploan = () => ploan.current.scrollIntoView(false) 
    
        const mortgage = useRef(null);
        const executemortgage = () => mortgage.current.scrollIntoView(false) 

        const ba = useRef(null);
        const executeba = () => ba.current.scrollIntoView(false) 

        const bf = useRef(null);
        const executebf = () => bf.current.scrollIntoView(false) 

        const credit = useRef(null);
        const executecredit = () => credit.current.scrollIntoView(false) 


    return(

    <div style={{height: "100%"}}>
        <Social/>
        <section class="navbar-service">
            <div>
            <Header/>
            </div>
            </section>

            <section class="service-img">
                    <div class="service-top" style={{ backgroundImage: `url(${service})`}}>
                    <p class ="service-text">Services</p>
                    </div>      
            </section>
            <section class="service-side-main">

                <div class="quick-access">
                    <div class="quick-links-middle-wrapper">
                        <h class="quick-link-header"> Quick Clicks: </h>
                            <ul class="ul-quicklinks">
                                <li class="li-quick-links" onClick={executeploan} >Personal Loans</li>
                                <li class="li-quick-links" onClick={executemortgage}>Mortgage</li>
                                <li class="li-quick-links" onClick={executebf}>Business Loan</li>
                                <li class="li-quick-links" onClick={executeba}>Business Account</li>
                                <li class="li-quick-links" onClick={executecredit}>Credit Cards</li>
                            </ul>
                    </div>

                </div>
                <div class="service-details-main-wrapper">
                <div class="personal-section-wrapper">
                <div class="personal-main-wrapper">
                <div class="middle-personal-wrapper" ref={ploan}>
                    <h id="services-header">Personal Loans</h>

                    <p id="paragraph" class="para-one">Excellence Financing Broker makes Personal Loan easy for 
                        employees of all Listed & Non-Listed companies in UAE. 
                        We compare and help our customers to get the lowest interest
                         rates to fulfil urgent financial requirements.</p>

                    <p id="paragraph">Excellence Financing Broker provides the platform to compare 
                        all banks across the UAE at your fingertips. Just by filling 
                        few details, you can receive a quotation from all the major banks
                         in UAE within 30 minutes.</p>
                    
                    <p id="paragraph">So, why worry? Apply for a personal loan with us, and we will provide
                         the best option suitable for your requirements.</p>

                    <BookAppointmentServices/>

                </div>  
                <div class="middle-personal-wrapper" ref={mortgage}>
                    <h id="services-header">Mortgage</h>

                    <p id="paragraph" class="para-one">Welcome to the home of mortgage solutions. When you are looking 
                    to invest in the UAE property market or just want a home away from home, Excellence Financing Broker 
                    provides the best course of action to make your dream come true!</p>

                    <p id="paragraph">Excellence Financing Broker assist customers with the best Mortgage offers/options 
                    in the UAE. Buying a new home has never been easier when a dedicated mortgage advisor is available to 
                    give all the support and advice. We assist in getting Quick Mortgage loan processing with the lowest
                     interest rates. We have ventured with an elite range of Banks and Financial institutions that allow us
                      to assist in selecting a suitable product for our customers.</p>
                    
                    <p id="paragraph">Contact us today and check your eligibility. We help our customers with a quick and 
                    easy application process.</p>

                    <BookAppointmentServices/>
                </div>


                <div class="middle-personal-wrapper" ref={bf}>
                    <h id="services-header">Business Loan</h>

                    <p id="paragraph" class="para-one">Our Team of expert will 
                    help you to get a Business Loan for all types of companies, 
                    business entities which aids in providing you with all types 
                    of facilities also related to Bussiness LC (Letter of Credit), 
                    Trade finance, Project Finance.</p>

                    <p id="paragraph" class="para-one">Collaborating with Excellence 
                    Financing Broker will help you to get facilities with hassle free 
                    Financial Procedure & also provides you with
                    Flexible repayment options.</p>
                    <BookAppointmentServices/>
                </div>

                <div class="middle-personal-wrapper" ref={ba}>
                    <h id="services-header">Bussiness Accounts</h>

                    <p id="paragraph" class="para-one">Bussiness account is required for all companies to manage 
                    its Business activities.</p>

                    <p id="paragraph" class="para-one">
                        Out Team of experts is the easiest way to apply for Business Account
                        for all types of companies FZCO/LLC/SOLE ESTABLISHMENTS to offer smooth & 
                        hassle free Financial procedures for our esteemed valued customers.
                    </p>

                    <BookAppointmentServices/>
                </div>

                <div class="middle-personal-wrapper" ref={credit}>
                    <h id="services-header" >Credit Cards</h>

                    <p id="paragraph" class="para-one">Excellence Financial Broker helps 
                    credit card seekers to get best Credit Card in UAE as per their requirements.
                     Each credit card comes with its own pair of benefits. An applicant can compare
                      and apply for a credit card through us. Our knowledge and excellence will help 
                      you to select the best credit card options. Selecting a type makes the process
                       simpler and quicker when you have a clear goal for credit card acquisition.</p>

                    <p id="paragraph">Contact us today and get the best offers in credit cards e.g. Free 
                    for life, Air Miles, Movie or cinema offers, Cash Back, Reward & Discount Offers, Airport 
                    Lounge Access, Dining & Cuisine Discounts, Balance Transfer & Quick cash, Travel Benefits,
                     Golf Benefits, Shariah-Compliant.</p>
                    
                    <p id="paragraph">Our experienced and dedicated team works substantially to compare Credit 
                    Cards in UAE banks and financial institutions to assist out customers with the best offers in 
                    Credit Cards and a hassle-free application process.</p>

                    <BookAppointmentServices/>
                </div> 
            </div>
                            
                            
        </div>
                        
                </div>
            </section>

        <section class="footer-service-class
        ">
            <Footer/>
        </section>
    </div>


    )
}

export default withRouter(Service);




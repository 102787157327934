import React from 'react'
import '../components/appointment.css'
import moment from 'moment'
import {
    Form,
    Input,
    Button,
    Select,
    DatePicker,
    
  } from 'antd';
  import '../components/appointment.css'
  import axios from 'axios'



const Booking = () =>{

   const [form] = Form.useForm();
    
  const { Option } = Select;
    const validateMessages = {
        required: '${label} is required!',
        types: {
          email: '${label} is not a valid email!',
          number: '${label} is not a valid number!',
        }
      };
    
      const layout = {
        labelCol: {
          span: 8,
        },
        wrapperCol: {
          span: 16,
        },
      };
    
      const onFinish = (e) => {
        form.resetFields();
            console.log(e);
         e.date = moment(e.date).format(
           "dddd, MMMM Do YYYY" 
         ) 
          axios.post('https://efb.ae/appointments/add', e )
            .then(res=> {console.log(res.data)})
            .catch(err=>{
              console.log(err)
            })
      };

      const prefixSelector = (
        <Form.Item name="prefix" noStyle>
          <Select style={{ width: 70 }}>
            <Option value="86">+1</Option>
            
          </Select>
        </Form.Item>
      );
    

    

    return(
                 <div class="form-background">
                     <Form {...layout} name="nest-messages"
                     form={form} 
                     onFinish={onFinish} 
                     validateMessages={validateMessages}>
        <Form.Item
              name={['Name']}
              label="Name"
              rules={[
                {
                  required: true,
                },
              ]}
              className="ant-form-item-appoint-home"
            >
              <Input />
      </Form.Item>

        <Form.Item name={['email']} label="Email" 
        rules={[{ required: true, message: 'Please input your email' }]}
        className="ant-form-item-appoint-home">
        <Input />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Phone Number"
        className="ant-form-item-appoint-home"
        rules={[{ required: true, message: 'Please input your phone number!' }]}
      >
        <Input style={{ width: '100%' }} />
      </Form.Item>

        <Form.Item name={['services']} 
        label="Services" 
        className="ant-form-item-appoint-home"
        rules={[{ required: true, message: 'Please select any one service' }]}
        >
          <Select >
            <Select.Option value="Personal Loans">Personal Loans</Select.Option>
            <Select.Option value="Credit Cards">Mortgage</Select.Option>
            <Select.Option value="Mortgage">Bussiness Loan</Select.Option>
            <Select.Option value="Auto Loan">Bussiness Account</Select.Option>
            <Select.Option value="Bussiness Loans">Credit Cards</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item name={['date']}
        label="Request A Call: "
        className="ant-form-item-appoint-home"
        rules={[{ required: true, message: 'Please select  date ' }]}
        >
          <DatePicker />
        </Form.Item>


        <Button type="primary" htmlType="submit" className="bookAppointmentHomebutton">Submit Request</Button>

        </Form>                        
</div>      

    )
}


export default Booking;
import React from 'react'
import{ useState} from 'react'
import { Drawer, Button } from 'antd';
import sidemenu from '../components/Login/login_img/side_menu.png'
import {Link} from 'react-router-dom';
import SidebarLogin from '../components/Login/sidebarlogin'
import BankerLogin from '../components/Login/bankerLogin'

const SideMenu = () => {

  const [visible, setVisible] = useState(false)
  const [servicechildrenDrawer, setServiceChildrenDrawer] = useState(false)
  const [loginchildrenDrawer, setLoginChildrenDrawer] = useState(false)
  const [registerchildrenDrawer, setRegisterChildrenDrawer] = useState(false)

  const showDrawer = () => {
    setVisible(true)
  };

  const onClose = () => {
    setVisible(false)
  };

  const showServiceChildrenDrawer = () => {
    setServiceChildrenDrawer(true)
  };

  const onServiceChildrenDrawerClose = () => {
    setServiceChildrenDrawer(false)
  };
  
  const showLoginChildrenDrawer = () => {
    setLoginChildrenDrawer(true)
  };

  const onLoginChildrenDrawerClose = () => {
    setLoginChildrenDrawer(false)
  };

  const showRegisterChildrenDrawer = () => {
    setRegisterChildrenDrawer(true)
  };

  const onRegisterChildrenDrawerClose = () => {
    setRegisterChildrenDrawer(false)
  };

    return (
      <>
        <div class="side-menu"><img  src={sidemenu} alt="Side Menu" onClick={showDrawer}></img></div>
        <Drawer
          width={520}
          closable={false}
          onClose={onClose}
          visible={visible}
        >

            <ul class="Side-Menu-ul-class">
            <Link to ="/"> 
            <li class="side-menu-li-class">Home</li>
            </Link >
            <Link to ="/about">
                <li class="side-menu-li-class">Why EFB</li></Link>

            <Link >    
                <li class="side-menu-li-class" onClick={showServiceChildrenDrawer} >Services</li></Link>
            <Link>    
                <li class="side-menu-li-class" onClick={showLoginChildrenDrawer}>Login</li></Link>
            <Link>    
                <li class="side-menu-li-class" onClick={showRegisterChildrenDrawer}>Register</li></Link>

            </ul>
       
          <Drawer
            title="Services"
            width={320}
            closable={false}
            onClose={onServiceChildrenDrawerClose}
            visible={servicechildrenDrawer}
          >
              <ul class="Side-Menu-ul-class">
              <Link to={{
                    pathname: '/services',
                    state: {
                      service: "ploan" }
                  }}>
                <li key="1" class="side-menu-li-class">Personal Loan</li></Link>
                <Link to={{
        pathname: '/services',
        state: {
          service: "mortgage" }
      }}>
                <li key="2" class="side-menu-li-class">Mortgage</li></Link>
                <Link to={{
        pathname: '/services',
        state: {
          service: "bf" }
      }}>
                <li key="3" class="side-menu-li-class">Business loan</li></Link>
                <Link to={{
        pathname: '/services',
        state: {
          service: "ba" }
      }}>
                <li key="4" class="side-menu-li-class">Business Account</li></Link>
                <Link to={{
        pathname: '/services',
        state: {
          service: "credit" }
      }}>
                <li key="5" class="side-menu-li-class">Credit Cards</li></Link>
            </ul>
          </Drawer>

          <Drawer
            title="Login"
            width={320}
            closable={false}
            onClose={onLoginChildrenDrawerClose}
            visible={loginchildrenDrawer}
          >
            <ul class="Side-Menu-ul-class">
                <li key="1" class="side-menu-li-class">Customer Login</li>
                <li key="2" class="side-menu-li-class">Banker Login</li>
                
            </ul>
          </Drawer>

          <Drawer
            title="Register"
            width={320}
            closable={false}
            onClose={onRegisterChildrenDrawerClose}
            visible={registerchildrenDrawer}
          >
            <ul class="Side-Menu-ul-class">
                <li key="1" class="side-menu-li-class">Customer Registration</li>
                <li key="2" class="side-menu-li-class">Bankers Registration</li>
                
            </ul>
          </Drawer>

        </Drawer>
      </>
    );
  }
export default SideMenu;




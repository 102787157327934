import React from 'react'
import '../components/social.css'
import {
    faFacebook,
    faInstagram,
    faWhatsapp,
    faLinkedin,
  } from "@fortawesome/free-brands-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Social = () =>{

return(

<div >
    <div class="provized_social_float" style={{zIndex: 999999}}>
        <a href="https://www.facebook.com/ExcellenceFinancingBroker/" style={{background: "rgba(0,124,255,1.00)"}} class="provized_social_icon"> <FontAwesomeIcon icon={faFacebook} size="1x" /></a>
        <a href="https://www.instagram.com/excellencefinancingbroker/" id="instagram" class="provized_social_icon"><FontAwesomeIcon icon={faInstagram} size="1x" /> </a>
        {/* <a href="www.google.com" style={{background: "rgba(245,71,74,1.00)"}} class="provized_social_icon"><FontAwesomeIcon icon={faTwitter} size="1x" /></a> */}
        <a href="https://www.linkedin.com/company/excellencefinancingbroker/" style={{background: "rgba(0,0,0,1.00)"}} class="provized_social_icon"><FontAwesomeIcon icon={faLinkedin} size="1x" /> </a>
        <a href="https://web.whatsapp.com/send?phone=+97143412266&text=Hi, I contacted you Through your website." style={{background: "rgba(0,0,0,1.00)"}} class="provized_social_icon"><FontAwesomeIcon icon={faWhatsapp} size="1x" /> </a>
    </div>
</div>
    )
}

export default Social;
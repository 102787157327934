import React, {useState,useEffect} from "react";
import { Table, Space } from 'antd';
import axios from "axios";
import { Link } from "react-router-dom";

const UserAppointment =()=>{

  const [users,setUsers] = useState([]);
  // let history = useHistory();



  // const onClick = () =>{
  //   history.push("/messages")
  // }

  const email = localStorage.getItem("email")

useEffect(()=>{

  axios.post('https://efb.ae/appointments/user-appointment', {email})
  .then(res=> {
      console.log(res)
      setUsers(res.data.appointments)
  }); 
},[])

      const columns = [
        {
          title: 'Name',
          dataIndex: 'Name',
          key: 'name',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Phone Number',
          dataIndex: 'phone',
          key: 'phone',
        },

        {
            title: 'Service',
            dataIndex: 'services',
            key: 'services',
          },
          {
            title: 'Appointment Date',
            dataIndex: 'date',
            key: 'date',
          },

          {
            title: 'Messages',
            key: 'action',
            render: (text, record) => (
  
            <Link to={{
              pathname:"/messages",
              state:{
                email: record.email,
                Name: record.Name
              }  
            }}>    
              <Space size="middle" >
                {console.log(record.Name)}
                Messages
              </Space>
            </Link>  
            ),
          },
      ];
      return(
        <Table columns={columns} dataSource={users} />
      )
    }
export default UserAppointment;
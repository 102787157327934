import React from 'react'
import Header from '../head'
import './userAppointment.css'
import UserAppointment from './userAppointment'

const UserAppointmentDash = () =>{

    


    return(

        <>
            <div className="uapp-header">
                <Header/>
            </div>
            <div className="uapp-title-main">
                <h1 className="uapp-title">Your Appointments</h1>
            </div>
            <div class="uapp-table-main-div">
                <UserAppointment/>
            </div>
        </>

    )
}

export default UserAppointmentDash;
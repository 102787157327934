import React from 'react'
import { Drawer, Button, Form, Input, Menu } from 'antd';
import axios from 'axios';
import emailjs from 'emailjs-com'
import '../Login/fogot.css'
import Header from '../head'
import { useState, } from 'react'


const ForgotPassword = () =>{

 

    const [form] = Form.useForm();
    const [vcodebutton, setVcodebutton] = useState(true)


    const formItemLayout = {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 10 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 6 },
        },
      };

      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 0,
            offset: 8,
          },
        },
      };


    const onFinish = (email) =>{
  
      axios.post('https://efb.ae/register/forgot-password', email)
          .then(res=> {
            console.log(res.data.vcode)
            
            localStorage.setItem('ForgotData', JSON.stringify(res.data))
            if (res.data === null){
              setVcodebutton(true)
            }
            else {
              setVcodebutton(false)

              var templateParams = {
                email: res.data.email,
                name: res.data.Name,
                code: res.data.vcode
            };
              console.log(templateParams)
              emailjs.send('service_a187eue','template_ktq7b02', templateParams,'user_xwAGv6OcSGNs6fKURQ64N')
                .then((result) => {
                    console.log(result.text);
                }, (error) => {
                    console.log(error.text);
                });
            }
          })
          
          .catch(err=>{
            form.resetFields();
          })
    }


    const onFinish2 =(e)=> {

      console.log(e)

      axios.post('https://efb.ae/register/verify-code', e)
        .then(res=>{
          form.resetFields();
          console.log(res.data)
        

        })

        .catch(err=>{
          form.resetFields();
        })


    }

    return(
        <>
        <Header/>
        <section class="forgot-title">
            <h>Forgot Password ?</h>
            <p>Please enter your registered Email id</p>
        </section>
        <section class="forgot-form-wrapper">

      {vcodebutton ?    
        <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{
                email:'',
                prefix: '+91',
              }}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item >
              <Form.Item {...tailFormItemLayout}>
                
                <Button  type="primary" htmlType="submit">
                              Send me verification code
                </Button>
                </Form.Item>        
            </Form>
        :
        <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish2}
              initialValues={{
                email:'',
                prefix: '+91',
              }}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item >

            <Form.Item
                name="encodedvcode"
                label="Verification Code"
                rules={[{ required: true, message: 'Please input OTP received in mail' }]}
              >
                <Input />
              </Form.Item> 
              
             
              
            <Form.Item
                name="newpassword"
                label="New Password"
                rules={[{ required: true, message: 'Please your new password'}]}
              >
                <Input />
              </Form.Item> 
              
           

              <Form.Item {...tailFormItemLayout}>
                
                            <Button  type="primary" htmlType="submit">
                              Verify
                            </Button>
                </Form.Item>        
        </Form>
        } 
        </section>
    </>    
    )
}

export default ForgotPassword;
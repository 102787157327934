import React, { useState } from 'react';
import { Drawer, Button, Form, Input, Menu } from 'antd';
import './register.css'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";


const BankerLogin = (props) => {

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError]= useState(false);
  const [loader, setloader]= useState(false)

  const showDrawer = () => {
    setVisible(true);
    setError(false)
    setSuccess(false)
  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
    setError(false)
    setSuccess(false);
       
  };

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

  const onFinish = async (e) => {
    setloader(true)
    setError(false)
    setSuccess(false)

    axios.post('https://efb.ae/bankerRegister/login-banker', e )
    .then(res=> {
      form.resetFields();
      console.log(res.data);
      setSuccess(true);
      setError(false);
      setloader(false)
      localStorage.setItem('bankerAuth', res.data)
      props.history.push("/")
      })


     .catch(err=>{
       console.log(err);
       setError(true);
       setSuccess(false);
       form.resetFields();
       setloader(false)
     })

          
  };

  return (
    <>
      <Menu.Item key="2" onClick={showDrawer}>Banker Login</Menu.Item>
      
      <Drawer
        title="Banker Sign In"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        {loader ?
         <p class="alert-text">Processing....</p>
        : null }

        {success ?
            <p class="alert-pass-text">Login Successfully</p>
            : null}  
                  
        {error ? 
            <p class="alert-text">Email or Phone Number exists</p>
            : null} 
       <section style={{height: "100%"}}>
              <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
              initialValues={{
                email:'',
                prefix: '+91',
              }}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>


              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit">
                  Log In
                </Button>
              </Form.Item>
            </Form> 

            <div class="forgot">
              <Link to="/forgot-password-banker" class="forgot-password">Forgot Password?</Link>
              </div>                                             
      </section>
            
      </Drawer>
    </>
  );
};

export default withRouter(BankerLogin);
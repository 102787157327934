import React from 'react'
import p_loan from '../img/loans.jpg'
import credit_cards from '../img/credit-cards.jpg'
import mortgage from '../img/mortgage.jpg'
import auto_loan from '../img/car-loan.jpg'
import bussiness_loan from '../img/business-loan.jpg'
import insurance from '../img/insurance.jpg'
import { Carousel } from 'antd';
import '../components/slider.css'


const Slider = () =>{

const images = [
	p_loan,
	credit_cards,
	mortgage,
	auto_loan,
	bussiness_loan,
	insurance

];

const contentStyle = {
	height: '400px',
	color: 'black',
	lineHeight: '160px',
	textAlign: 'center',
	background: 'White',
  };

return(

	<Carousel autoplay>
    <div>
      <div  style={contentStyle}>
		<div  className="c-class-wrapper-main">
			<div className="img-slider-class">
				<img className="slider-img-wrap" src={p_loan} alt="Personel Loan"></img> 
			</div>
			<div className="para-main-wrapper" >
				<p className="header-slider-wrapper">Personal Loan</p>
				<p className="paras-slider-wrapper">Excellence Financing Broker makes Personal
						Loan easy for employees of all Listed & 
						Non-Listed companies in UAE. We compare 
						and help our customers to get the lowest
						interest rates to fulfil urgent financial 
						requirements.</p>
			</div>		
			</div>
		</div>
    </div>
	<div>
      <div style={contentStyle}>
	  	<div  className="c-class-wrapper-main">
			<div className="img-slider-class">
				<img className="slider-img-wrap" src={mortgage} alt="Mortgage"></img>
			</div>
			<div className="para-main-wrapper">
				<p className="header-slider-wrapper">Mortgage</p>
				<p className="paras-slider-wrapper">Welcome to the home 
				of mortgage solutions. When you are looking to invest in
				 the UAE property market or just want a home away from home,
				  Excellence Financing Broker provides the best course of action 
				  to make your dream come true!</p>
			</div>		
		</div>
	  </div>
    </div>
	<div>
      <div style={contentStyle}>
	  	<div  className="c-class-wrapper-main">
			<div className="img-slider-class">
				<img className="slider-img-wrap" src={bussiness_loan} alt="Business Loan"></img>
			</div>
			<div className="para-main-wrapper">
				<p className="header-slider-wrapper">Business Loan</p>
				<p className="paras-slider-wrapper">Our Team of expert will 
                    help you to get a Business Loan for all types of companies, 
                    business entities which aids in providing you with all types 
                    of facilities also related to Bussiness LC (Letter of Credit), 
                    Trade finance, Project Finance.</p>
			</div>		
		</div>
	  </div>
    </div>
	<div>
      <div style={contentStyle}>
	  	<div  className="c-class-wrapper-main">
			<div className="img-slider-class">
				<img className="slider-img-wrap" src={insurance} alt="Bussiness Account"></img>
			</div>
			<div className="para-main-wrapper">
				<p className="header-slider-wrapper">Bussiness Account</p>
				<p className="paras-slider-wrapper" >Bussiness account is required for all companies to manage 
                    its Business activities.</p>
			</div>		
		</div>
	  </div>
    </div>
    <div>
      <div style={contentStyle}>
		<div  className="c-class-wrapper-main">
				<div className="img-slider-class">
					<img className="slider-img-wrap" src={credit_cards} alt="Credits Cards"></img>
				</div>
				<div className="para-main-wrapper">
					<p className="header-slider-wrapper">Credits Cards</p>
					<p className="paras-slider-wrapper">Our experienced and dedicated team
						works substantially to compare Credit
							Cards in UAE banks and financial institutions
							to assist out customers with the best offers
							in Credit Cards and a hassle-free application
							process.</p>
				</div>		
			</div>
	  </div>
    </div>
  </Carousel>

)


}
export default Slider;

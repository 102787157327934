import React, {useState,useEffect} from "react";
import { Table, notification } from 'antd';
import axios from "axios";
import '../userAppointmentList/userMessage.css'
import { Button, Form, Input,} from 'antd';
import Header from '../head'
import InstantMessage from '../userAppointmentList/instantMessage'
import FileUpload from '../userAppointmentList/fileUpload'

const UserMessage =(props)=>{

const [form] = Form.useForm();

const { TextArea } = Input;
const [message ,setMessage] = useState([]);
const [files ,setFiles] = useState([]);


const User_Email = localStorage.getItem("email");
const cus_email = localStorage.getItem("email");

// const User_Name = (props.location.state.Name);
// const Emp_name = localStorage.getItem("name")

  useEffect(()=>{
    console.log(" Inside ",User_Email)
    axios.post('https://efb.ae/message/get-message', {User_Email})
    .then(res=> {
        console.log(res.data)
        setMessage(res.data.msg)
    });
    
    axios.post('http://localhost:3002/fileupload/filelist', {cus_email})
    .then(res=> {
        console.log(res.data)
        setFiles(res.data.files)
    });
    
  },[])

  const columns = [
    {
      title: 'Sender Name',
      dataIndex: 'SenderName',
      key: 'SenderName',
    },
    {
      title: 'Message',
      dataIndex: 'Query',
      key: 'Query',
    },
  ];

  const columnsFiles = [
    {
      title: 'File Uploader',
      dataIndex: 'cus_name',
      key: 'cus_name',
    },
    {
      title: 'File',
      dataIndex: 'filename',
      key: 'filename',
    },
  ];
      return(

        <>
        <div className="uapp-header">
                <Header/>
        </div>

        <div class="msg-header">
            <h1 className="comman-class">Message History</h1>
        </div>
        <div className="comman-class">
        <Table columns={columns} dataSource={message} />
        </div>

        <div class="msg-header">
            <h1 className="comman-class">Files Shared</h1>
        </div>
        <div className="comman-class">
        <Table columns={columnsFiles} dataSource={files} />
        </div>

        <div className="comman-class form-class" style={{width:"100%"}}>

          <div class="msg-header">
            <div class="msg-body">
              <h1>Reply</h1>
              <InstantMessage/>
            </div>
            <div class="msg-body">
              <h1>File Upload</h1>
              <FileUpload/>
            </div>  
          </div>
        </div>
        </>
      )
    }
export default UserMessage;
import { Menu, Dropdown, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import SidebarLogin from './sidebarlogin';
import BankerLogin from './bankerLogin'


const LoginList =() =>{

const menu = (
    <Menu >
      <SidebarLogin/>
      <BankerLogin/>
    </Menu>
  );

             
    return(
        <Dropdown overlay={menu}>
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
      Login <DownOutlined />
    </a>
  </Dropdown>   
    
    )
}


export default LoginList;

import Footer from '../components/footer'
import Header from '../components/head'
import React from 'react'
import '../App.css'
import banner_home_2 from '../img/banner-home-2.jpg'
import '../components/about.css'
import about from '../img/about.jpg'
import Social from '../components/social'
import {Link} from 'react-router-dom';

const About = () =>{

    return(
     <div style={{height: "100%"}}>
         <Social/>   
        <section class="sectionAbout">
            <div><Header/></div>

            <div class="aboutImg">
            
            <div class="aboutTop" style={{ backgroundImage: `url(${banner_home_2})`}}>
            <p class ="aboutPageText">Why EFB</p>
             </div>      
            </div>
        </section>
        <section class="sectionTwoabout" >

            <div class="aboutBody" style={{ backgroundImage: `url(${about})`}}></div>
            <div class="aboutPageHeader">
            <div class="abouthead-para">
            <h1 class="headingAbout">About Excellence Financing Broker</h1>

                    <ul class="ulsectTwoAbout">
                        <li class="ilsectTwoAbout" >
                        EXCELLENCE FINANCING BROKER works as a 
                        Financial Advisor to support customers with the 
                        best financial products suitable to their needs.
                        </li>

                        <li class="ilsectTwoAbout">
                        Our organization works on its core value “TRUST
                        and LOYALTY”. We’re determined to use our knowledge, 
                        experience and, intellect to find the best solutions to client’s requirements.
                        </li >

                        <li class="ilsectTwoAbout">
                        We assist you in getting all financial requirements such as Salary Transfer Loan, 
                        Non-Salary Transfer Loan, Debt Consolidation / Buyout, Current Accounts, Savings Accounts, 
                        Credit Card, Auto loan, Business loan, mortgage loans, and Insurance/Investment.
                        </li>
                    </ul>    
                </div>
            </div> 
        </section>
        <section class="aboutFooter">
            <Footer/>
        </section>
        <div class="credit-footer">
                <div class="credit-text">
                   <p class="credit-p-tag">@ All right resevred by EFB</p>
                   <Link to="/privacy-policy-and-disclaimer" class="credit-p-tag">Privacy Policy</Link>
                   <Link to="/privacy-policy-and-disclaimer" class="credit-p-tag">Disclaimer</Link>
                </div>
            </div>
     </div>
        
        
    )
}

export default About;
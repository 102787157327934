import React, { useState } from 'react';
import { Drawer, Button, Form, Input, Menu } from 'antd';
import './register.css'
import axios from 'axios';
import { Link } from 'react-router-dom';
import { withRouter } from "react-router";


const SidebarLogin = (props) => {

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError]= useState(false);
  const [loader, setloader]= useState(false)

  const showDrawer = () => {
    setVisible(true);
    setError(false)
    setSuccess(false)

  };

  const onClose = () => {
    setVisible(false);
    form.resetFields();
    setError(false)
    setSuccess(false);
    setloader(false)
       
  };


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

  const onFinish = (e) => {
    console.log(e);

    axios.post('https://efb.ae/register/user-login', e)
        // setloader(true)
    .then(res=> {
        form.resetFields();
        console.log(res.data);
        setSuccess(true);
        setError(false);
        setloader(false)
        localStorage.setItem('UserAuth', res.data.accessToken)
        localStorage.setItem('email', res.data.email)
        localStorage.setItem('name', res.data.name)
        props.history.push("/")


        console.log(e);
    })
     .catch(err=>{
          console.log(err);
          form.resetFields();
          setError(true);
          setSuccess(false);
          setloader(false)
     }) 
  };

  

  return (
    <>
      <Menu.Item key="1" onClick={showDrawer}>Customer Login</Menu.Item>
      
      <Drawer
        title="Sign In"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        {loader ?
         <p class="alert-text">Processing....</p>
        : null }
        
        {error ?
        <p class="alert-text">Invalid Credentials</p>
        : null} 
       

       {success ? 
            <p class="alert-pass-text">Login Successful</p>
            : null} 

          <section style={{height: "100%"}}>  
              <Form
              {...formItemLayout}
              form={form}
              name="register"
              onFinish={onFinish}
             
              initialValues={{
                email:'',
                prefix: '+91',
              }}
              scrollToFirstError
            >
              <Form.Item
                name="email"
                label="E-mail"
                rules={[
                  {
                    type: 'email',
                    message: 'The input is not valid E-mail!',
                  },
                  {
                    required: true,
                    message: 'Please input your E-mail!',
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
                hasFeedback
              >
                <Input.Password />
              </Form.Item>


              <Form.Item {...tailFormItemLayout}>
                <Button type="primary" htmlType="submit" >
                  Log In
                </Button>
              </Form.Item>

              <div class="forgot">
              <Link to="/forgot-password" class="forgot-password">Forgot Password?</Link>
              </div>

            </Form>                                                
      </section>

              
      </Drawer>
    </>
  );
};

export default withRouter(SidebarLogin);
import { Menu, Dropdown, message } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Register from './register'
import BankerRegister from './bankerRegister'


const RegisterList =() =>{

const onClick = ({ key }) => {
  message.info(`Click on item ${key}`);
};

const menu = (
    <Menu>
      <Register/>
      <BankerRegister/>
    </Menu>
  );

             
    return(
        <Dropdown overlay={menu}>
    <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
      Register <DownOutlined />
    </a>
  </Dropdown>   
    
    )
}


export default RegisterList;
